/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InitialInverterItemResponse { 
    manufacturer: string;
    stationCode: string;
    stationName: string;
    latitude: number;
    longitude: number;
    stationStatus?: string;
}

