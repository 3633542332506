/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceDataItemResponseDataInnerDataPV { 
    pv1V?: string;
    pv1A?: string;
    pv2V?: string;
    pv2A?: string;
    pv3V?: string;
    pv3A?: string;
    pv4V?: string;
    pv4A?: string;
    pv5V?: string;
    pv5A?: string;
    pv6V?: string;
    pv6A?: string;
    pv7V?: string;
    pv7A?: string;
    pv8V?: string;
    pv8A?: string;
}

