/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceDataItemResponseDataInnerDataPV } from './deviceDataItemResponseDataInnerDataPV';
import { DeviceDataItemResponseDataInnerDataGrid } from './deviceDataItemResponseDataInnerDataGrid';


export interface DeviceDataItemResponseDataInner { 
    inverterId: string;
    deviceId: string;
    sn: string;
    reactivePower: string;
    internalTemperature: string;
    activePower: string;
    powerFactor: string;
    gridFrequency: string;
    efficiency?: string;
    dayCap?: string;
    totalCap?: string;
    inverterState?: string;
    runState?: string;
    startupTime?: string;
    shutdownTime?: string;
    dataPV?: DeviceDataItemResponseDataInnerDataPV;
    dataGrid?: DeviceDataItemResponseDataInnerDataGrid;
}

