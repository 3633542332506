/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InviteAdminRequestRoleAnyOf1 } from './inviteAdminRequestRoleAnyOf1';
import { InviteAdminRequestRoleAnyOf2 } from './inviteAdminRequestRoleAnyOf2';
import { InviteAdminRequestRoleAnyOf } from './inviteAdminRequestRoleAnyOf';


export interface InviteAdminRequestRole { 
}

