/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InverterItemResponseExtendedData } from './inverterItemResponseExtendedData';


export interface InverterItemResponse { 
    id: string;
    manufacturer: string;
    stationCode: string;
    stationName: string;
    stationStatus?: string;
    capacity: number;
    contactMethod?: string;
    contactPerson?: string;
    latitude: number;
    longitude: number;
    stationAddress: string;
    stationTimeZone?: string;
    inverterType?: string;
    extendedData?: InverterItemResponseExtendedData;
}

