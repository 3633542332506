/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceDataItemResponseDataInnerDataGrid { 
    grid1V?: string;
    grid1A?: string;
    grid2V?: string;
    grid2A?: string;
    grid3V?: string;
    grid3A?: string;
}

