/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChartRequestUnit } from './chartRequestUnit';


export interface ChartRequest { 
    unit: ChartRequestUnit;
    inverterId: string;
    timezone: string;
    specificDate?: string;
}

