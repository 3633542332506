/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InverterItemResponseExtendedData { 
    gridConnectionDate?: string;
    dateInPlantTimeZone?: string;
    timezoneOffset?: string;
    gridType?: string;
    angle?: number;
    slope?: number;
    customerId?: string;
    customerMobile?: string;
    inverterNumber?: string;
    idCode?: string;
    avgPrice?: number;
    userId?: string;
}

