/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceListItemResponseDataInner { 
    inverterId: string;
    stationCode?: string;
    devType: number;
    devTypeDescription?: string;
    sn?: string;
    devDn?: string;
    devName?: string;
    deviceId: string;
    devModel?: string;
    softwareVersion?: string;
    protocalVersion?: string;
}

