/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.29.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AlarmItemResponse { 
    inverterId: string;
    manufacturer: string;
    stationCode: string;
    stationName: string;
    alarmCause?: string;
    alarmId: number;
    alarmName: string;
    alarmType?: number;
    alarmTypeDescription?: string;
    devName: string;
    deviceId: number;
    deviceIdDescription?: string;
    sn: string;
    level: number;
    levelDescription?: string;
    raiseTime: string;
    repairSuggestion?: string;
}

